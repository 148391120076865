
.tablesupport {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 10px;
    border: 1px solid rgba(205, 205, 205, 1);
}
th, td {
    border: 1px solid rgba(205, 205, 205, 1);
    padding: 15px;
} 

tr:first-child th:first-child {
    border-top-left-radius: 10px;
}

tr:first-child th:last-child {
    border-top-right-radius: 10px;
}

tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
}

tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
}
.arrow{
    width: 24px;
    height: 24px;
    border-radius:3px;
    background-color: rgba(225, 225, 225, 1);
}
.dropdown {
 position: absolute;
 width: 300px;
 top: 35%;
 left:40%;
 box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
 border-radius: 10px;
 padding: 16px;
}
.border{
    height: 55px;
    border:1px solid #C2C9D1;
    border-radius: 5px;
}
.dropdown-header .avatar {
    background-color:#6C6C6C;
    border-radius: 50%;
}
.viewcard{
    width:900px;
    /* background-color: '#FFFFFF'; */
    border-radius: '16px';
    border: none;
}
.describeproblem{
    border-radius: 8px;
    border: 1px solid #8692A6;
    background-color: #F8F8F8;
}
/* text area */
textarea:focus { 
    outline: none !important;
    border: 1px solid #8692A6;
}
.viewimg{
    width: 450px;
    height: 220px;
    border-radius: 5px;
}
.assignagent{
    width: 370px;
    height: 60px;
    border-radius: 10px;   
}

::placeholder{
    color: #717171;
}
.search-btn{
    width: 420px;
    padding: 10px; 
    height: 50px;
    
}
