.popover-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 48px;
    /* margin-right: 20px; */
    /* margin-top: 65px; */
    /* border: 2px solid red; */
  }
  
  .popover {
    /* margin-left: 3px; */
    position: relative;
    min-width: 290px;
    height: 280px;
    background-color: white;
    border-radius: 10px;
    margin-top: 150px;
    border: 2px solid #8692A6;
  }
  
  .popover-arrow {
    position: absolute;
    top: -20px;
    left: 37px;
    height: 30px;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid white;
    width: 0;
    height: 0;
    -webkit-filter: drop-shadow(0 -2px 2px #AAA);
  }