@import url('https://fonts.googleapis.com/css2?family=Inter&family=DM+Sans&display=swap');

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}
body{
    background-color: #F8F8F8;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: #03565a;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}


/* Custom CSS */
.font-size-12 {
    font-size: 12px !important;
}

.font-size-14 {
    font-size: 14px !important;
}

.font-size-16 {
    font-size: 16px !important;
}

.font-size-18 {
    font-size: 18px !important;
}

.font-size-20 {
    font-size: 20px !important;
}

.font-size-24 {
    font-size: 24px !important;
}  

.custom-font-500 {
    font-weight: 500 !important;
}

.custom-font-600 {
    font-weight: 600 !important;
}

.custom-font-700 {
    font-weight: 700 !important;
}
.custom-font-800 {
    font-weight: 800 !important;
}

.primary-border {
    border: 1px solid #03565A !important;
}

.custom-common-theme-text-color {
    color: #03565A !important;
}

.radius-6px {
    border-radius: 6px !important;
}

.radius-10px {
    border-radius: 10px !important;
}

.custom-common-theme-bg-color {
    background-color: #03565A !important;
}

.custom-border {
    border: 1px solid #D6D6D6;
}

.custom-cercle {
    height: 79px;
    width: 79px;
    background-color: #FDC27B;
    border-radius: 100%;
}

input[placeholder="MM/DD/YYYY"] {
   margin-left: 20px;
   /* margin-top: -10px; */
}
.top-redious{
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.no-shadow {
    box-shadow: none !important;
  }
/*  */

.no-border {
    border: none !important;
  }

  .border-5 {
    border-radius:5px;
  }
  .pointer {
    cursor: pointer;
  }
/*  */
.Mui-checked {
    color: #03565A !important;
}

.gray-borderColor{
    border: 1px solid #D6D6D6 !important;

}
.arrow{
    width: 24px;
    height: 24px;
    border-radius:3px;
    background-color: rgba(225, 225, 225, 1);
}
.textfield{
    border: 1px solid black;
    /* / padding: 1px; / */
}

.dropdown {
 position: absolute;
 width: 300px;
 top: 40%;
 left:40%;
 background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
border: 1px solid #dcdcdc;
border-radius: 10px;
margin-top: 5px;
padding: 16px;
   
}

.border{
    width: 100%;
    color: #0f0101;
    border: none;
    border-radius: 2px;
    /* / padding: 12px; / */
    font-size: 16px;
    /* / cursor: pointer; / */
}

.dropdown-header {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    /* / width: 100%; / */
    /* height:42px; */
    border-radius: 5px;
    margin-top: 5px;
    padding-left: 14px;
}

.underline {
    text-decoration: underline;
}

.dropdown-header .avatar {
    background-color: #777;
    color: #fff;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    font-size: 14px;
    font-weight: 600;
}

.dropdown-header span {
    font-size: 16px;
    font-weight: 500;
}

.dropdown-title {
    font-size: 14px;
    font-weight: 600;
    color: #777;
    margin-bottom: 8px;
    margin-top: 20px;
}

.dropdown-option {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    margin-top: 20px;
}

.dropdown-option .avatar {
    background-color: #006666;
    color: #fff;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    font-size: 14px;
    font-weight: 600;
}

.dropdown-option span {
    font-size: 16px;
    font-weight: 500;
}

.assign-button {
    width: 100%;
    background-color: #006666;
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 12px;
    font-size: 16px;
    cursor: pointer;
}

.assign-button:hover {
    background-color: #005555;
}

.swal-button{
    background-color: #03565A !important;
  }
  .swal-button:not([disabled]):hover{
    background-color: #04666A !important;
  }
  .swal-button--cancel{
    color: white;
  }

  .w-30 {
    width:30%;
  }
  .Table-headerText{
    color:  #717171;
  }
  /*  */
 .MuiAutocomplete-hasPopupIcon.css-1si7jif-MuiAutocomplete-root .MuiOutlinedInput-root{
    border-radius: 10px !important;
 }
.MuiAutocomplete-hasPopupIcon.css-uzum2u-MuiAutocomplete-root .MuiOutlinedInput-root{
    border-radius: 10px !important;

}
.gray-border{
border: 1px solid #D6D6D6;
}

.selected-agent {
    background:#d0f1f3;
}

.border-15{
    border-radius: 15px;
}
.border-1{
    border:1px solid rgb(190, 189, 189);
}

.border-radius-5{
    border-radius: 10px;
}

.text-custome{
    color: #03565A;
}
.border-custom{
    border:2px solid #03565A;
}

.bg-grey{
    background: rgb(245, 241, 241);
}

.date-label-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
  }
  
  .date-label-container hr {
    flex-grow: 1;
    border: none;
    border-top: 1px solid #ccc;
    margin-right: 20px;
  }
  
  .date-label-container .date-label {
    position: absolute;
    background-color: white;
    padding: 0 10px;
    font-size: 10px;
    color: gray;
  }
.custom-backgroundColor{
    background-color:  #F8F8F8 !important;
}
/* chek box */
.custom-checkbox:checked {
    accent-color: #03565A ;
}

.custom-bg-white-150 {
    background-color: #F8F8F8;
  }
